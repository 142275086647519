<template>
  <main class="login">
    <div class="container">
      <h1><img src="/img/logo-black-gold.png" alt="Logo" /></h1>
      <form @submit.prevent="sendResetLink">
        <div class="username" v-if="!sent">
          <label for="email">{{ $t("overall.email") }}</label>
          <input id="email" placeholder="info@uniqestates.be" type="email" name="email" v-model="email" />
        </div>
        <div v-else>An email was sent to your email to reset your password !</div>
        <div class="error" v-if="error">{{ error }}</div>
        <button class="cta">{{ $t("Reset my password !") }}</button>
      </form>
    </div>
  </main>
</template>

<script>
import Vue from "vue";
import axios from "axios";
export default Vue.extend({
  name: "Login",
  data() {
    return {
      email: "",
      sent: false,
      error: null,
      loading: false,
    };
  },
  methods: {
    sendResetLink() {
      this.$axios
        .post("login/send-reset-password", {
          email: this.email,
        })
        .then((res) => {
          this.sent = true;
        })
        .catch((res) => {
          this.error = "Nom d'utilisateur ou mot de passe incorrect";
        });
    },
  },
});
</script>

<style scoped></style>
